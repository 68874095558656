import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Images2 = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "about-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 900, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img classname="image media" fluid={data.placeholderImage.childImageSharp.fluid} />
}

export default Images2