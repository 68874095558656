import React from "react"
import serviceStyles from './title.module.scss'

export default function Service(props) {
  return (
    <div className={serviceStyles.service}>
      <span className={serviceStyles.check}></span>
      <p className="is-size-6"><b>{props.text}</b></p>
    </div>
  )
}