import React from "react"
import { Link } from "gatsby"
import '../styles/global.scss'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero/"
import AboutImage from "../components/DoubleImage/"
import Title from "../components/Title/"
import Service from "../components/Service/"
import Feature from "../components/Feature/"
import GoogleMap from '../components/map'

import KnowlageIcon from "../icons/knowlage.svg"
import SafeIcon from "../icons/safe.svg"
import ExperianceIcon from "../icons/experiance.svg"
import CellPhone from "../icons/cell_phone.svg"
import Telephone from "../icons/telephone.svg"
import Email from "../icons/email.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Biuro Rachunkowe Jolanta Saska | Kraków" />
    <Hero heroTitle="Twój biznes bez przeszkód!" heroSubtitle="Profesjonalne biuro rachunkowe." />

    <section className="section">
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column is-half-desktop">
            <div className="content">
              <h2 data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease-out-quint" data-sal-duration="1000" className='title is-1 is-spaced'><Title text="O firmie" /></h2>
              <p data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease-out-quint" data-sal-duration="1000" className='subtitle has-text-weight-medium'>
                Szanowni Państwo, Biuro Rachunkowe Jolanta Saska działa od 2002 roku na podstawie Licencji Ministra Finansów nr 34547/02.
              </p>
              <p data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease-out-quint" data-sal-duration="1000">
                Specjalizuje się w obsłudze <b>małych i średnich przedsiębiorstw</b> w zakresie ewidencji księgowych oraz
                rozliczeń kadr i płac. Biuro ponosi pełną odpowiedzialność za prawidłowość prowadzonych ksiąg i rozliczeń
                klientów przed US i ZUS. Dodatkowo działalność Biura jest ubezpieczona w zakresie OC.
              </p>
              <p data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease-out-quint" data-sal-duration="1000">
                Jeżeli jesteś zainteresowany podjęciem współpracy z naszym biurem rachunkowym prosimy o kontakt telefoniczny
                lub mailowy w celu podania danych niezbędnych do podpisania umowy na prowadzenie usług księgowych.
                Podpisanie umowy oraz upoważnień może nastąpić w siedzibie Biura Rachunkowego lub w siedzibie zleceniodawcy.
                Biuro Rachunkowe składa wszystkie niezbędne dokumenty w odpowiednim dla Państwa firmy Urzędzie Skarbowym
                oraz Zakładzie Ubezpieczeń Społecznych.
              </p>
              <p data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease-out-quint" data-sal-duration="1000">
                Jeżeli zamierzają Państwo dopiero założyć działalność gospodarczą prosimy o kontakt telefoniczny lub mailowy
                w celu umówienia się w siedzibie biura rachunkowego na wypełnienie niezbędnych formularzy podatkowych oraz
                wyboru najlepszej formy opodatkowania dla Państwa nowej firmy.
              </p>
              <div data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease-out-quint" data-sal-duration="1000" className="block-space-top">
                <Link className="button is-outlined is-primary" to="/o-firmie">Więcej o naszym biurze</Link>
              </div>
            </div>
          </div>
          <div className="column is-half-desktop">
            <div data-sal="slide-left" data-sal-delay="300" data-sal-easing="ease-out-quint" data-sal-duration="1000">
              <AboutImage />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section has-bg-color">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-out-quint">
              <Feature
                text="Wieloletnia praktyka oraz cigłe podwyższanie kwalifikacji pozwalają nam świadczyć usługi na najwyższym poziomie."
                icon={<KnowlageIcon width="42px" height="42px" />}
              />
            </div>
          </div>
          <div className="column">
            <div data-sal="slide-up" data-sal-delay="320" data-sal-easing="ease-out-quint">
              <Feature
                text="Wypracowane procedury oraz wdrożone nowoczesne technologie dają gwarancję, że usługi wykonujemy z dbałością o bezpieczeństwo danych."
                icon={<SafeIcon width="42px" height="42px" />}
              />
            </div>
          </div>
          <div className="column">
            <div data-sal="slide-up" data-sal-delay="340" data-sal-easing="ease-out-quint">
              <Feature
                text="Długoletnie doświadczenie pozwala nam zagwarantowć merytoryczny profesjonalizm."
                icon={<ExperianceIcon width="42px" height="42px" />}
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <h2 className='title is-1 is-spaced'><Title text="Nasze usługi" /></h2>
        <div className="columns is-multiline">
          <div className="column is-one-third is-one-quarter-widescreen">
            <div data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-out-quint">
              <Service text="Prowadzenie Ryczałtu Ewidencjonowanego" />
            </div>
          </div>
          <div className="column is-one-third is-one-quarter-widescreen">
            <div data-sal="slide-up" data-sal-delay="320" data-sal-easing="ease-out-quint">
              <Service text="KPiR" />
            </div>
          </div>
          <div className="column is-one-third is-one-quarter-widescreen">
            <div data-sal="slide-up" data-sal-delay="340" data-sal-easing="ease-out-quint">
              <Service text="Prowadzenie Ksiąg Rachunkowych" />
            </div>
          </div>
          <div className="column is-one-third is-one-quarter-widescreen">
            <div data-sal="slide-up" data-sal-delay="360" data-sal-easing="ease-out-quint">
              <Service text="Prowadzenie dokumentacji kadrowej" />
            </div>
          </div>
          <div className="column is-one-third is-one-quarter-widescreen">
            <div data-sal="slide-up" data-sal-delay="380" data-sal-easing="ease-out-quint">
              <Service text="Prowadzenie dokumentacji płacowej" />
            </div>
          </div>
          <div className="column is-one-third is-one-quarter-widescreen">
            <div data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-out-quint">
              <Service text="Obsługa płacowa i rozlicznia ZUS" />
            </div>
          </div>
          <div className="column is-one-third is-one-quarter-widescreen">
            <div data-sal="slide-up" data-sal-delay="420" data-sal-easing="ease-out-quint">
              <Service text="Sporządzanie rozliczeń rocznych" />
            </div>
          </div>
          <div className="column is-one-third is-one-quarter-widescreen">
            <div data-sal="slide-up" data-sal-delay="440" data-sal-easing="ease-out-quint">
              <div className="service-item">
                <Link className="button is-outlined is-primary" to="/oferta">Sprawdz wszystkie usługi</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section has-bg-color">
      <div className="container">
        <h2 className='title is-1 is-spaced'><Title text="Cennik" /></h2>
        <div className="columns is-8 is-variable">
          <div className="column">
            <div className="content">
              <div className="subtitle is-6">
                <p>Usługi księgowe i rachunkowe czyli rachunkowość dla firm prowadzimy w oparciu o model indywidualnej wyceny dla każdego Klienta. Koszt usług uzależniony jest m.in. od:</p>
              </div>
              <ul>
                <li>Formy ksiąg podatkowych - Księgi Handlowe, KPiR, ryczałt </li>
                <li>Formy prawnej - osoba fizyczna, spółka cywilna, Spółka z o.o. </li>
                <li>Branży - działalność usługowa, handlowa, produkcyjna </li>
                <li>Ilości dokumentów i zapisów księgowych </li>
                <li>Ilości zatrudnionych pracowników </li>
                <li>Stopnia złożoności rozliczeń podatkowych </li>
                <li>Innych - firma jest płatnikiem VAT, transakcje WNT, eksport - import, rozlicza projekt unijny, itp. </li>
              </ul>
              <div className="block-space-top">
                <Link className="button is-outlined is-primary" to="/cennik">Dowiedz się więcej</Link>
              </div>
            </div>
          </div>
          <div className="column">
              <div className="content">
                <h3 className="title is-6">Przykładowe ceny podstawowych pakietów miesięcznych</h3>
              </div>
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>Usługa</th>
                    <th>Cena netto</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Prowadzenie książki przychodów i rozchodów z vat:</th>
                    <th>od 250 PLN</th>
                  </tr>
                  <tr>
                    <th>Prowadzenie ksiąg handlowych firm o profilu handlowym i usługowym:</th>
                    <th>od 800 PLN</th>
                  </tr>
                  <tr>
                    <th>Za każdorazowe sporządzenie bilansu i rachunku wyników:</th>
                    <th>od 800 PLN</th>
                  </tr>
                  <tr>
                    <th>Reprezentowanie podatnika przed urzędem, zusem w czasie kontroli podatkowej</th>
                    <th>od 70 PLN/godz.</th>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </section>

    <section id="contact" className="section contact-section">
      <div className="contact-section__half"></div>
      <div className="container">
        <div className="columns">
          <div className="column is-5">
            <div className="contact-section__content has-text-white">
              <h3 className='title is-1 is-spaced has-text-white'><Title text="Kontakt" /></h3>
              <div className="has-text-white contact-info">
                Jolanta Saska - Biuro Rachunkowe <br />
                ul. Vetulaniego 1A / pok. 210 <br />
                31-227 Kraków <br />
                NIP: 945-13-43-698 <br/>
              </div>
              <div className="columns is-multiline">
                <div className="column is-6">
                  <div className="contact-item">
                    <CellPhone width="32px"  height="32px" />
                    <a href="tel:+48502276292">502 276 292</a>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="contact-item">
                    <Telephone width="32px"  height="32px" />
                    <a href="tel:+48124202130">12 420 21 30</a>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="contact-item">
                    <Email width="32px"  height="32px" />
                    <a href="mailto:jolanta.saska@gmail.com">jolanta.saska@gmail.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-7">
            <div className="map-container">
              <GoogleMap />
            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default IndexPage
