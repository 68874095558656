import React from "react"
import heroStyles from './hero.module.scss'
import HeroImage from "./heroImage"
import { Link } from "gatsby"

export default function Hero(props) {
  return (
    <section className={`hero has-background is-large is-fullheight is-fullheight-with-navbar is-primary ${heroStyles.background}`}>
      <div className={heroStyles.heroImage}>
        <HeroImage />
      </div>
      <div className="hero-body">
        <div className="container">
          <div className={heroStyles.head}>
            <h1 className={`title ${heroStyles.title}`}>
              {props.heroTitle}
            </h1>
            <h2 className={`title ${heroStyles.subtitle}`}>{props.heroSubtitle}</h2>
          </div>
          <Link className="button is-primary is-inverted is-outlined" to="/oferta">Zobacz naszą ofertę</Link>
        </div>
      </div>
    </section>
  )
}
