import React from "react"
import doubleimageStyles from './doubleimage.module.scss'
import Image from "./Images"
import Image2 from "./Images2"

export default function Hero(props) {
  return (
    <div className={doubleimageStyles.doubleImage}>
      <div className={doubleimageStyles.blockPictures}>
        <div className={`${doubleimageStyles.doubleImageCover} ${doubleimageStyles.image}`}>
          <Image />
        </div>
        <div className={`${doubleimageStyles.doubleImageCover} ${doubleimageStyles.image}`}>
          <Image2 />
        </div>
        <div className={`${doubleimageStyles.doubleImageCover} ${doubleimageStyles.image} ${doubleimageStyles.imageHover}`}>
          <Image2 />
        </div>
      </div>
    </div>
  )
}
