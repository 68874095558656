import React from "react"
import featureStyles from './feature.module.scss'

export default function Feature(props) {
  return (
    <div className={featureStyles.feature}>
      <div className={featureStyles.icon}>
        {props.icon}
      </div>
      <p className="">{props.text}</p>
    </div>
  )
}